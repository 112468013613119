import React, { useContext } from "react"
import loadable from "@loadable/component"

// Context
import { StoreContext } from "../../context/store-context"

// Components
const EmptyCart = loadable(() => import("./emptyCart"))
const Summary = loadable(() => import("./summary"))
const LineItem = loadable(() => import("./lineItem"))
const CtaBoxes = loadable(() => import("../promotions/ctaBoxes"))

const Cart = () => {
  const { checkout, loading, updateLineItem } = useContext(StoreContext)
  const emptyCart = checkout.lineItems.length === 0

  return (
    <div>
      <div className="max-w-screen-lg mx-auto py-8 sm:py-12 px-4">
        <div className="mb-6">
          <p className="font-bold text-2xl mb-3">Tvoja Košarica</p>
          <hr />
        </div>
        {emptyCart ? (
          <>
            <EmptyCart />
            <section className="max-w-screen-xl mx-auto pb-10 sm:pb-16">
              <CtaBoxes borderTop={false} />
            </section>
          </>
        ) : (
          <div>
            <div className="grid grid-cols-1 gap-6">
              {checkout.lineItems.map(item => (
                <LineItem
                  checkout={checkout}
                  item={item}
                  key={item.id}
                  loading={loading}
                  updateLineItem={updateLineItem}
                />
              ))}
            </div>
            <hr className="mt-6" />
            <Summary
              checkout={checkout}
              emptyCart={emptyCart}
              loading={loading}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Cart
