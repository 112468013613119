import React from "react"

// Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import Cart from "../components/cart"

const ShopifyDemoPage = ({ location, data }) => {
  return (
    <Layout path={location.pathname}>
      <Seo title="Tvoja Košarica" lang="sl" />
      <Cart />
    </Layout>
  )
}

export default ShopifyDemoPage
